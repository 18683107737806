<template>
  <div
    class="slider"
    :style="{ width: `${width}px`}"
  >
    <div
      class="slider__slides overlay"
      :style="{ width: `${width * slides.length}px`, transform: `translateX(-${ width * current_index }px)`}"
    >
      <div
        class="slides__slide slide"
        :style="{ backgroundImage: `url(${translate(slide.image)})`, width: `${width}px`}"
        v-for="(slide, index) in slides"
        :key="`slide-${index}`"
      >
        <div class="container">
          <div class="slide__content">
            <h1
              class="slide__title bold"
              v-if="index === 0 && translate(slide.title)"
            >
              <span>
                {{ translate(slide.title) }}
              </span>
            </h1>

            <h2
              class="slide__title bold"
              v-else-if="translate(slide.title)"
            >
              <span>
                {{ translate(slide.title) }}
              </span>
            </h2>

            <div
              class="editor__content margin__t--18 slide__text"
              v-html="translate(slide.text)"
              v-if="translate(slide.text)"
            />

            <app-link
              :link="slide.cta"
              class="btn btn__size--small cta margin__t--18"
              v-slot="{ text }"
            >
              <span>
                {{ text }}

                <fa-icon
                  class="margin__l--12"
                  :icon="['fal', 'long-arrow-right']"
                  fixed-width
                />
              </span>
            </app-link>
          </div>
        </div>
      </div>
    </div>

    <nav
      class="slider__nav container"
      v-if="slides.length > 1"
    >
      <ul class="nav__ul">
        <li
          class="nav__li"
          v-for="(slide, index) in slides"
          :key="`nav-${index}`"
        >
          <button
            class="nav__bullet"
            :class="{ 'is-active': current_index === index }"
            @click="current_index = index"
          />
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { ref, toRefs, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useWindowSize } from '@/composables/app/useWindowSize'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))

const props = defineProps({ slides: { type: Array, default: () => [] } })

const { translate } = useI18n()
const { width } = useWindowSize()

const { slides } = toRefs(props)

const current_index = ref(0)
</script>

<style lang="scss" scoped>
$color--primary: #00AEEF;
$color--secondary: #202243;
$color--tertiary: #FFFF00;

.slider {
  position: relative;

  &__slides {
    display: flex;
    transition: all .3s ease-in-out;
    background: $color--secondary;
  }

  &__slide {
    flex: 1 0 100%;
  }

  &__nav {
    padding-top: 24px;
    padding-bottom: 24px;

    @include mq(md) {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      padding-bottom: 34px;
    }
  }
}

.slide {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: $white;
  padding: 96px 0;

  @include mq(sm) {
    padding: 120px 0 230px;
  }

  &::before {
    content: '';
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(#131415, 0.19);
  }

  &__content {
    position: relative;
    max-width: 650px;
    z-index: 10;
  }

  &__title {
    font-size: rem(40px);
    line-height: 1;
    display: inline-block;
    font-family: $font-family--secondary;
    text-transform: uppercase;
    word-wrap: break-word;
    max-width: 100%;

    @include mq(sm) {
      font-size: rem(60px);
    }

    span {
      display: inline;
      background-color: $color--primary;
      box-shadow: $color--primary -0.2em 0px 0px 1px, $color--primary 0.2em 0px 0px 1px, $color--primary 0px 0px 0px 1px;
      -webkit-box-decoration-break: clone;
    }
  }
}

.nav {
  &__ul {
    display: flex;
    gap: 30px;
  }

  &__li {
    flex: 1 1 auto;

    @include mq(md) {
      flex: 0 0 70px;
    }
  }

  &__bullet {
    display: block;
    width: 100%;
    height: 3px;
    background: #f5f5f5;
    transition: background 1.3s ease-in-out;

    @include mq(md) {
      background: rgba(#fff, 0.7);
      width: 70px;
    }

    &:hover,
    &.is-active {
      background: $color--primary;

      @include mq(md) {
        background: $white;
      }
    }
  }
}
</style>