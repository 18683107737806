<template>
  <footer class="footer">
    <div class="container footer__wrapper">
      <div>
        <a
          class="logo"
          :href="translate(column_1.logo.url)"
          target="_blank"
          v-if="translate(column_1.logo.url)"
        >
          <img
            class="logo__img"
            :src="translate(column_1.logo.image) || client.logo.url"
            :alt="client.name"
          >
        </a>

        <router-link
          :to="{ name: 'Home', params: { locale } }"
          class="logo"
          v-else
        >
          <img
            class="logo__img"
            :src="translate(column_1.logo.image) || client.logo.url"
            :alt="client.name"
          >
        </router-link>

        <app-social-networks
          :facebook="['fab', 'facebook-f']"
          :linkedin="['fab', 'linkedin']"
        />
      </div>

      <div>
        <h4
          class="footer__title"
          v-if="translate(column_2.title)"
        >
          {{ translate(column_2.title) }}
        </h4>

        <nav class="navigation">
          <ul class="navigation__ul">
            <li
              v-for="({ link }, index) in column_2.navigation"
              :key="`navigation_${index}`"
              class="navigation__li"
            >
              <app-link
                :link="link"
                class="navigation__link link--naked link__color--white-ter"
              />
            </li>

            <li
              class="navigation__li navigation__li--language"
              v-if="client_languages.length"
            >
              <language-switcher class="language-switcher">
                <template #selected-option="{ option }">
                  <span class="language-switcher__option">
                    <img
                      class="language-switcher__flag"
                      :src="`https://koalect-images.s3.eu-west-3.amazonaws.com/flags/flag_${option.id}.png`"
                      :alt="`Flag of ${option.label.name}`"
                    >

                    {{ option.label.name }}
                  </span>
                </template>

                <template #option="{ option }">
                  <span class="language-switcher__option">
                    <img
                      class="language-switcher__flag"
                      :src="`https://koalect-images.s3.eu-west-3.amazonaws.com/flags/flag_${option.id}.png`"
                      :alt="`Flag of ${option.label.name}`"
                    >

                    {{ option.label.name }}
                  </span>
                </template>

                <template #open-indicator>
                  <fa-icon
                    class="language-switcher__arrow"
                    :icon="['far', 'chevron-down']"
                    fixed-width
                  />
                </template>
              </language-switcher>
            </li>
          </ul>
        </nav>
      </div>

      <div>
        <h4
          class="footer__title"
          v-if="translate(column_3.title)"
        >
          {{ translate(column_3.title) }}
        </h4>

        <ul class="contact">
          <li
            class="contact__li"
            v-if="translate(column_3.email?.value)"
          >
            <figure
              class="contact__icon"
              aria-hidden="true"
            >
              <fa-icon :icon="['fas', 'envelope']" />
            </figure>

            <div>
              <h5
                class="contact__title"
                v-if="translate(column_3.email.title)"
              >
                {{ translate(column_3.email.title) }}
              </h5>

              <a
                :href="`mailto:${translate(column_3.email.value)}`"
                class="link__color--white-ter link--naked"
              >
                {{ translate(column_3.email.value) }}
              </a>
            </div>
          </li>

          <li
            class="contact__li"
            v-if="translate(column_3.address.value)"
          >
            <figure
              class="contact__icon"
              aria-hidden="true"
            >
              <fa-icon :icon="['fas', 'location-dot']" />
            </figure>

            <div>
              <h5
                class="contact__title"
                v-if="translate(column_3.address.title)"
              >
                {{ translate(column_3.address.title) }}
              </h5>

              <address>
                {{ translate(column_3.address.value) }}
              </address>
            </div>
          </li>
        </ul>
      </div>

      <div>
        <ul
          class="call_to_actions"
          v-if="column_4.cta.length"
        >
          <li
            v-for="({ link }, index) in column_4.cta"
            :key="`cta_${index}`"
            class="call_to_actions__li"
          >
            <app-link
              :link="link"
              class="cta btn btn__color--prim btn__size--classic"
            />
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useClientStore } from '@/stores/ClientStore'
import { useLanguageStore } from '@/stores/LanguageStore'
import { useI18n } from '@/vendors/i18n'

import AppSocialNetworks from '&/molecules/AppSocialNetworks'
const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const LanguageSwitcher = defineAsyncComponent(() => import('&/atoms/LanguageSwitcher'))

const { section } = defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()
const { getClient } = useClientStore()
const { locale, client_languages } = storeToRefs(useLanguageStore())

const { client } = getClient()

const { column_1 = {}, column_2 = {}, column_3 = {}, column_4 = {} } = section.attributes
</script>

<style lang="scss" scoped>

:deep(.cta) {
  display: block;
  font-family: $font-family--secondary;
  color: #575758;
  font-style: italic;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  padding: 16px 28px;
  line-height: 1.4;
  font-size: rem(17px);

  &:hover {
    background: $color--tertiary;
    color: $white;
  }
}

.footer {
  background: #575758;
  color: $white;
  padding: 60px 0;

  @include mq(sm) {
    padding: 80px 0;
  }

  @include mq(md) {
    padding: 120px 0;
  }

  &__wrapper {
    display: grid;
    gap: 50px;
    grid-template-columns: 1fr;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(md) {
      gap: 30px;
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__title {
    font-family: $font-family--secondary;
    font-size: rem(20px);
    line-height: 1.3;
    font-style: italic;
    padding: 10px 0 15px;
  }
}

.logo {
  display: block;

  &__img {
    width: auto;
    height: 117px;

    @include mq(sm) {
      height: 162px;
    }
  }
}

:deep() {
  .networks {
    &__ul {
      display: flex;
      font-size: rem(19px);
      gap: 20px;
      margin-top: 50px;
    }

    &__a {
      color: $white;

      &:hover {
        color: $color--primary;
      }
    }
  }
}

.navigation {
  &__li {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  :deep() {
    .navigation {
      &__link {
        font-family: $font-family--secondary;
        font-size: rem(17px);
        line-height: 1.4;
        font-style: italic;
        text-transform: uppercase;

        &.is-active {
          color: $color--primary;
        }
      }
    }
  }
}

.language-switcher {
  justify-content: flex-start;

  &__flag {
    width: 18px;
    height: auto;
  }

  &__option {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  &__arrow {
    transition: .3s ease-in-out;

    .vs--open & {
      transform: rotate(-180deg);
    }
  }

  :deep() {
    .locale-switcher {
      background: none;
    }

    .v-select {
      border: none;
    }

    .vs {
      &__dropdown-toggle {
        color: $white;
        padding: 0;

        &:hover {
          color: $color--primary;

          .vs {
            &__selected {
              color: $color--primary;
            }
          }
        }
      }

      &__selected {
        color: $white;
        font-style: italic;
        text-transform: uppercase;
        font-size: rem(17px);
        line-height: 1.4;
        font-family: $font-family--secondary;
      }

      &__actions {
        font-size: rem(10px);
      }

      &__search {
        &:focus {
          border: 0;
        }
      }

      &__dropdown-menu {
        border: 0;
        background: #575758;
        min-width: 170px;
      }

      &__dropdown-option {
        color: $white;
        font-family: $font-family--primary;
        font-size: rem(17px);
        line-height: 1.2;
        padding: 10px 15px;

        &--highlight {
          color: $color--primary;
          background: none;
        }
      }
    }
  }
}

.contact {
  &__li {
    padding: 5px 0;
    display: grid;
    grid-template-columns: 40px auto;
    gap: 15px;
    align-items: center;
    font-size: rem(14px);
    line-height: 1.5;
  }

  &__icon {
    background: $color--tertiary;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #575758;
  }

  &__title {
    font-family: $font-family--secondary;
    font-size: rem(17px);
    line-height: 1.4;
    font-style: italic;
    text-transform: uppercase;
  }
}

.call_to_actions {
  &__li {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}
</style>
